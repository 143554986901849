// Проверка поддержки webp, добавление класса webp или no-webp для HTML

export function isWebp() {
  function testWebP(callback) {
    let webP = new Image();
    webP.onload = webP.onerror = function () {
      callback(webP.height == 2);
    };
    webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
  }
  testWebP(function (support) {
    let className = support === true ? "webp" : "no-webp";
    document.documentElement.classList.add(className);
  });
}

// ОТКЛЮЧИТЬ :FOCUS ПО КЛИКУ, НО ПОКАЗЫВАТЬ ПРИ ТАББАНГЕ С КЛАВИАТУРЫ

export function focusTabbing() {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  }
  function handleMouseDownOnce() {
    document.body.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  }
  window.addEventListener("keydown", handleFirstTab);
}

// КНОПКА СКРОЛЛА НАВЕРХ

export function scrollToTop() {
  const scrollBtn = document.querySelector(".scroll-to-top");
  const btnVisibility = () => {
    if (window.scrollY > 400) {
      scrollBtn.style.visibility = "visible";
    } else {
      scrollBtn.style.visibility = "hidden";
    }
  };
  document.addEventListener("scroll", () => {
    btnVisibility();
  });
  scrollBtn.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
}

// НАВБАР ВСЕГДА ВВЕРХУ ЭКРАНА

export function stickyNavbar() {
  var topbar = document.querySelector(".topbar");
  var navbar = document.querySelector(".navbar");
  var navbarHeight = navbar.offsetHeight;
  var sticky = navbar.offsetTop;

  window.onscroll = function () {
    if (window.scrollY >= sticky) {
      navbar.classList.add("fixed-top");
      topbar.style.marginBottom = navbarHeight + "px";
    } else {
      navbar.classList.remove("fixed-top");
      topbar.style.marginBottom = 0;
    }
  };
}

// ЗАПУСТИТЬ АНИМАЦИЮ

export function animationPlay() {
  const sections = document.querySelectorAll("section");

  sections.forEach((section) => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.classList.add("play");
            }, index * 200);
          } else {
            entry.target.classList.remove("play");
          }
        });
      },
      { threshold: 0.2 }
    );

    const animationElements = section.querySelectorAll(".anim");

    animationElements.forEach((element) => {
      observer.observe(element);
    });
  });
}

// ПЛАВНЫЙ ДРОПДАУН В ВЕРТИКАЛЬНОМ МЕНЮ

export function sidebar() {
  document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".sidebar .nav-link").forEach(function (element) {
      element.addEventListener("click", function (e) {
        element.classList.toggle("active");

        let nextEl = element.nextElementSibling;
        let parentEl = element.parentElement;

        if (nextEl) {
          e.preventDefault();
          let mycollapse = new bootstrap.Collapse(nextEl);

          if (nextEl.classList.contains("show")) {
            mycollapse.hide();
          } else {
            mycollapse.show();
            //Если надо закрывать соседние сабменю:
            // var opened_submenu = parentEl.parentElement.querySelector('.submenu.show');
            // if (opened_submenu) {
            //    new bootstrap.Collapse(opened_submenu);
            // }
          }
        }
      });
    });
  });
}
