//СМАХИВАТЕЛЬ МОБИЛЬНОГО МЕНЮ И ЗАКРЫТИЕ ЕГО ПРИ КЛИКЕ НА ССЫЛКУ

import Xwiper from "xwiper";

let menuOffcanvasEl = document.getElementById("Offcanvas");
if (menuOffcanvasEl) {
  let menuOffcanvas = new bootstrap.Offcanvas(menuOffcanvasEl);
  const xwiper = new Xwiper("#Offcanvas");
  xwiper.onSwipeRight(() => {
    menuOffcanvas.hide();
  });

  const offcanvasLinks = document.querySelectorAll(".nav-item:not(.has-submenu) > .nav-link");
  offcanvasLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      menuOffcanvas.hide();

      setTimeout(() => {
        window.location.href = link.href;
      }, 200);
    });
  });
}

//ПЛАГИН МАСОК ИНПУТОВ

import IMask from "imask";

const phoneInput = document.querySelectorAll(".input-phone");

if (phoneInput.length) {
  phoneInput.forEach(function (el) {
    var phoneMask;

    el.addEventListener("focus", function () {
      phoneMask = IMask(el, {
        mask: "+{7} 000 000 00 00",
        lazy: false,
        placeholderChar: "*",
      });
    });

    el.addEventListener("blur", function () {
      phoneMask.updateOptions({
        mask: "+{7} 000 000 00 00",
        lazy: true,
      });
    });
  });
}

// ПРОСМОТРЩИК ФОТО

import { Fancybox } from "@fancyapps/ui";
Fancybox.bind("[data-fancybox]", {
  Toolbar: {
    display: {
      left: ["infobar"],
      right: ["zoomIn", "thumbs", "close"],
    },
  },

  l10n: {
    CLOSE: "Закрыть",
    NEXT: "Дальше",
    PREV: "Назад",
    MODAL: "Вы можете закрыть данное окно, нажав клавишу ESC",
    ERROR: "Что-то пошло не так. Пожалуйста, повторите попытку позже",
    IMAGE_ERROR: "Изображение не найдено",
    ELEMENT_NOT_FOUND: "HTML элемент не найден",
    AJAX_NOT_FOUND: "Ошибка загрузки AJAX : Не найдено",
    AJAX_FORBIDDEN: "Ошибка загрузки AJAX : Запрещено",
    IFRAME_ERROR: "Ошибка загрузки страницы",
    TOGGLE_ZOOM: "Переключить уровень масштаба",
    TOGGLE_THUMBS: "Переключить эскиз",
    TOGGLE_SLIDESHOW: "Переключить презентацию",
    TOGGLE_FULLSCREEN: "Переключить режим полного экрана",
    DOWNLOAD: "Скачать",
    ZOOMIN: "Увеличить",
    ZOOMOUT: "Уменьшить",
  },
});

//СЛАЙДЕР НА ПЕРВОМ ЭКРАНЕ

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

const mainSlider = document.querySelector(".main-slider");

if (mainSlider) {
  const swiper1 = new Swiper(mainSlider, {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 300,
    loop: true,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".sbn-1",
      prevEl: ".sbp-1",
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },

      992: {
        slidesPerView: 1,
        spaceBetween: 0,
      },

      1200: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  });
}

//СЛАЙДЕР ЭТАПОВ ПРОИЗВОДСТВА

const stepsSlider = document.querySelector(".steps-slider");

if (stepsSlider) {
  const swiper2 = new Swiper(stepsSlider, {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1.15,
    spaceBetween: 16,
    speed: 300,
    rewind: true,
    navigation: {
      nextEl: ".sbn-2",
      prevEl: ".sbp-2",
    },
    breakpoints: {
      768: {
        slidesPerView: 1.15,
        spaceBetween: 24,
      },

      992: {
        slidesPerView: 1,
        spaceBetween: 32,
      },

      1200: {
        slidesPerView: 1.065,
        spaceBetween: 32,
      },

      1400: {
        slidesPerView: 1.1,
        spaceBetween: 32,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    on: {
      afterInit: function (swiper2) {
        var fancyAttrName = swiper2.el.querySelector(".fbl").getAttribute("data-fancybox");
        var dupedSwipers = swiper2.el.querySelectorAll(".swiper-slide-duplicate");

        for (var i = 0; i < dupedSwipers.length; i++) {
          var dupedSwiperIndex = dupedSwipers[i].getAttribute("data-swiper-slide-index");

          dupedSwipers[i].querySelector(".fbl").setAttribute("data-fancybox-index", dupedSwiperIndex);
          dupedSwipers[i].querySelector(".fbl").removeAttribute("data-fancybox");
          dupedSwipers[i].querySelector(".fbl").setAttribute("data-fancybox-trigger", fancyAttrName);
        }
      },
    },
  });
}

//СЛАЙДЕР ОТЗЫВОВ

const reviewsSlider = document.querySelector(".reviews-slider");

if (reviewsSlider) {
  const swiper3 = new Swiper(reviewsSlider, {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1.25,
    spaceBetween: 16,
    speed: 300,
    loop: true,
    navigation: {
      nextEl: ".sbn-3",
      prevEl: ".sbp-3",
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 16,
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 24,
      },

      992: {
        slidesPerView: 2.25,
        spaceBetween: 16,
      },

      1200: {
        slidesPerView: 2,
        spaceBetween: 32,
      },

      1400: {
        slidesPerView: 2.5,
        spaceBetween: 32,
      },
    },
    pagination: {
      el: ".swiper-pagination-3",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    on: {
      afterInit: function (swiper3) {
        var fancyAttrName = swiper3.el.querySelector(".fbl").getAttribute("data-fancybox");
        var dupedSwipers = swiper3.el.querySelectorAll(".swiper-slide-duplicate");

        for (var i = 0; i < dupedSwipers.length; i++) {
          var dupedSwiperIndex = dupedSwipers[i].getAttribute("data-swiper-slide-index");

          dupedSwipers[i].querySelector(".fbl").setAttribute("data-fancybox-index", dupedSwiperIndex);
          dupedSwipers[i].querySelector(".fbl").removeAttribute("data-fancybox");
          dupedSwipers[i].querySelector(".fbl").setAttribute("data-fancybox-trigger", fancyAttrName);
        }
      },
    },
  });
}

//СЛАЙДЕР ТОВАРОВ

const productSliderContainers = document.querySelectorAll(".product-slider");

if (productSliderContainers.length) {
  for (const sliderContainer of productSliderContainers) {
    let slider = sliderContainer.querySelector(".swiper");
    let next = sliderContainer.querySelector(".sbn-4");
    let prev = sliderContainer.querySelector(".sbp-4");

    const swiper4 = new Swiper(slider, {
      modules: [Navigation],
      slidesPerView: 1.15,
      spaceBetween: 17,
      speed: 200,
      loopFillGroupWithBlank: true,
      loop: true,
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      breakpoints: {
        768: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },

        992: {
          slidesPerView: 1,
          spaceBetween: 0,
        },

        1200: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
      on: {
        afterInit: function (swiper4) {
          var fancyAttrName = swiper4.el.querySelector(".fbl").getAttribute("data-fancybox");
          var dupedSwipers = swiper4.el.querySelectorAll(".swiper-slide-duplicate");

          for (var i = 0; i < dupedSwipers.length; i++) {
            var dupedSwiperIndex = dupedSwipers[i].getAttribute("data-swiper-slide-index");

            dupedSwipers[i].querySelector(".fbl").setAttribute("data-fancybox-index", dupedSwiperIndex);
            dupedSwipers[i].querySelector(".fbl").removeAttribute("data-fancybox");
            dupedSwipers[i].querySelector(".fbl").setAttribute("data-fancybox-trigger", fancyAttrName);
          }
        },
      },
    });
  }
}

//ИМПОРТ ФУНКЦИЙ И ПЛАГИНОВ

import * as flsFunctions from "./functions.js";
flsFunctions.isWebp();
flsFunctions.focusTabbing();
flsFunctions.stickyNavbar();
flsFunctions.scrollToTop();
flsFunctions.sidebar();
flsFunctions.animationPlay();
